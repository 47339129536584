/* eslint-disable react-hooks/exhaustive-deps */
import Core from "../Core"
import Pagination from "./Pagination"
import { toast } from "react-toastify"
import * as api from "../../services/api"
import { useAuth } from "../../hooks/useAuth"
//@ts-ignore
import styles from "./styles.module.css"
import { FaSearch } from "react-icons/fa"
import { FaTrailer } from "react-icons/fa"
import { EditionModal } from "./EditionModal"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { DefaultMessage } from "../../utils/defaultMessages"
import { Fragment, useEffect, useRef, useState, RefObject } from "react"
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import { Avatar, Button, Collapse, Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, Typography, FormControl, Select, MenuItem, CircularProgress, TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, IconButton, SelectChangeEvent, InputLabel } from "@mui/material"
import GetAppIcon from "@mui/icons-material/GetApp";

interface SearchItem {
    id: string,
    date: string | null,
    region: string | null,
    state: string | null,
    city: string | null,
    invoiced: string | null,
    fabricator: string | null,
    brand_model: string | null,
    year_fabrication: string | null,
    axis: string | null,
    product: string | null,
    type_vehicle: string | null,
    chassi: string | null,
    plate: string | null,
    month_registration: string | null,
    year_registration: string | null,
    somos_code: string | null
}

interface TableRowData {
    nomeArquivo: string;
    criadoPor: string;
    criadoEm: string;
    atualizadoEm: string;
    status: string;
    totalLinhas: number;
    processadasComSucesso: number;
    processadasComErro: number;
    exportarResultado: string;
}

// const useLocalStorageMonitor = (key: string) => {

//     const [value, setValue] = useState<string | null>(() => localStorage.getItem(key));

//     useEffect(() => {
//         const handleStorageChange = (event: StorageEvent) => {
//             if (event.key === key) {
//                 setValue(event.newValue);
//             }
//         };

//         window.addEventListener('storage', handleStorageChange);

//         return () => {
//             window.removeEventListener('storage', handleStorageChange);
//         };
//     }, [key]);

//     return value;
// };

export const Pricing = () => {
    let [taskId, setTaskId] = useState<string | null>(localStorage.getItem('excel_task_id'));
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<string>('sea');
    const [totalPages, setTotalPages] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [somosCode, setSomosCode] = useState<number | string>('all');
    const [spreadRow, setSpreadRow] = useState<Array<any>>([]);
    const [indexSelected, setIndexSelected] = useState<number>(0);
    const [chassi_or_plate, setChassi_or_plate] = useState<string>('');
    const [dataSearch, setDataSearch] = useState<Array<SearchItem>>([]);
    const [fileSelected, setFileSelected] = useState<File | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const textFieldRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
    const [totalFounded, setTotalFounded] = useState(0);
    const [totalInDataBase, setTotalInDataBase] = useState(0);
    // const [initialDate, setInitialDate] = useState<string>('');
    // const [finalDate, setFinalDate] = useState<string>('');
    // const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    // const [tableData, setTableData] = useState<TableRowData[]>([]);
    const { user } = useAuth();

    const checkExcelTasIdStatus = () => {
        taskId = localStorage.getItem('excel_task_id');
        if (!taskId) {
            setTaskId(null);
        } else {
            setTaskId(taskId)
        }
    }

    // const excelTaskId = useLocalStorageMonitor('excel_task_id');

    // useEffect(() => {
    //     setTaskId(excelTaskId);
    // }, [excelTaskId]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const testJson = localStorage.getItem('task');
    let a = null
    if (testJson !== null) {
        a = JSON.parse(testJson);
    }

    const [spreadName, setSpreadName] = useState<string | null>(a ? a.task_id_name : null);
    const [spreadStatus, setSpreadStatus] = useState<string | null>(a ? a.task_id_status : null);

    const downloadXLSX = async (dataReq: any) => {
        let url = `?${new URLSearchParams(dataReq).toString()}`;
        let xlsxFileRoute = `${api.baseURL}/table/precificador/excel/filtered/data/param/${url}`;

        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${user?.access}`
            }
        };

        fetch(xlsxFileRoute, options)
            .then(response => response.json())
            .then(json => {
                setTaskId(json.content.task_id);
                toast.info("A geração do arquivo Excel foi iniciada. Você será notificado quando estiver pronto.", {
                    position: toast.POSITION.TOP_RIGHT
                });
                json.content.task_id && localStorage.setItem('excel_task_id', json.content.task_id);
            })
            .catch(err => {
                console.error(err);
                toast.error("Erro ao iniciar a geração do arquivo semitrailers.xlsx", {
                    position: toast.POSITION.TOP_RIGHT
                });
            });

        setInterval(checkExcelTasIdStatus, 15000);
    }

    const reset = () => {
        setBrowserOrFileName('Planilha Atualizada');
        setFileSelected(undefined);
    }

    const task_id = (id: string) => {
        api.get(`/table/check/status/tasks/?task_id=${id}`)
            .then((res: any) => {
                const ptStatus = res.data.content.status === 'SUCCESS' ? 'Sucesso' : res.data.content.status === 'PENDING' ? 'Pendente' : 'Falhou';
                let jsonInfo: object = { task_id: id, task_id_name: spreadName, task_id_date: '', task_id_status: ptStatus }
                const jsonInfoString = JSON.stringify(jsonInfo);
                localStorage.setItem('task', jsonInfoString);
                setSpreadStatus(ptStatus);
            }).catch((error) => {
                toast.dismiss()
                toast.error("Algo deu errado, verifique se o arquivo possui terminação '.xlsx'", {
                    position: toast.POSITION.TOP_RIGHT
                })
            })
    }

    const uploadUpdatedTable = () => {

        const data = new FormData();
        data.append("file", fileSelected!);

        api.post(
            `${api.baseURL}/table/upload/without/somoscodes/`,
            data,
            'multipart/form-data; boundary=---011000010111000001101001'
        ).then((response: any) => {
            if (response.status !== 200 && response.status !== 201 && response.status !== 202) {

                toast.dismiss()
                toast.warning(`${response.data.content.message}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
                reset()
            } else if (response.status === 202) {
                toast.dismiss()
                toast.warning(`${response.data.content.message}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
                reset()
                task_id(response.data.content.task_id);
                localStorage.setItem('taskID', response.data.content.task_id);
            } else {

                toast.dismiss()
                toast.success(`PLANILHA CADASTRADA!`, {
                    position: toast.POSITION.TOP_RIGHT
                })
                reset()
            }

            reset()

        }).catch((error) => {

            toast.dismiss()
            toast.error("Algo deu errado, verifique se o arquivo possui terminação '.xlsx'", {
                position: toast.POSITION.TOP_RIGHT
            })

            reset()

        })
    }
    const setModal = (stateModal: boolean) => {
        setIsOpen(stateModal);
    };

    const handlePageChange = (newPage: number) => {
        setIndexSelected(0)
        setCurrentPage(newPage);
    };

    const fetchData = async () => {

        try {
            const response: any = await api.get(`/table/license/plates/list/?page=${currentPage}`);
            let totalOfPages: number = response.data.content.pages;
            setTotalInDataBase(response.data.content.total);
            setTotalFounded(response.data.content.total);
            setLoading(false);
            setTotalPages(totalOfPages);
            setDataSearch(response.data.content.items);
        } catch (error) {
            toast.error(DefaultMessage.ERROR, {
                position: toast.POSITION.TOP_RIGHT
            })
        }

    }

    useEffect(() => {
        scrollToTop();
        setLoading(true);
        handleSearch(chassi_or_plate);
    }, [currentPage]);

    useEffect(() => {
        const id = localStorage.getItem('taskID');
        if (id) {
            task_id(id);
        }
        fetchData();
    }, []);

    type DataReq = {
        query?: string;
        somos_code?: number;
        page?: number;
        product?: string;
    }

    const handleSearch = async (event: string, download = false) => {

        setIndexSelected(0);
        // setCurrentPage(1);
        // setPage(1);

        
        if (!download) setLoading(true);
        
        let query = event;
        query.replaceAll(/[^\w\s]/gi, '').toUpperCase();
        
        let apiRoute = `/table/license/plates/list/`
        
        let dataReq: DataReq = {};
        
        try {
            await fetchData(); 
        } catch (error) {
            toast.error("Erro ao buscar os dados atualizados.", { position: toast.POSITION.TOP_RIGHT });
        } 
        if (query === "" && somosCode === 'all') {
            dataReq = {
            }
        } else if (query === "" && somosCode === 'false') {
            dataReq = {
                somos_code: 0,
            }
        } else if (query === "" && somosCode === 'true') {
            dataReq = {
                somos_code: 1,
            }
        } else if (query !== "" && somosCode === 'all') {
            dataReq = {
                query,
            }
        } else if (query !== "" && somosCode === 'false') {
            dataReq = {
                query,
                somos_code: 0,
            }
        } else if (query !== "" && somosCode === 'true') {
            dataReq = {
                query,
                somos_code: 1,
            }
        } else {
            apiRoute = `/table/license/plates/list/`;
        }

        if (download) {

            await downloadXLSX(dataReq);

        } else {
            try {
                dataReq = { ...dataReq, page: currentPage };
                const response: any = await api.get(apiRoute, dataReq);
                if (response.status === 200 || response.status === 202) {
                    // setTotalInDataBase(response.data.content.total);
                    setTotalFounded(response.data.content.total);
                    setTotalPages(response.data.content.pages);
                    setDataSearch(response.data.content.items);
                    setLoading(false)
                    // setPage(currentPage);
                    return
                } else {
                    toast.dismiss();
                    toast.error(response.data.content.message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setDataSearch([]);
                    setCurrentPage(1);
                    setLoading(false);
                }
            } catch (error) {
                toast.error(DefaultMessage.ERROR, {
                    position: toast.POSITION.TOP_RIGHT
                })
                setCurrentPage(1);
            }
        }

    }

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: any) => {
        const fileName = event.target.files[0]?.name;
        setSpreadName(fileName);
        setBrowserOrFileName(fileName);
        setFileSelected(event.target.files[0]);
    };

    const [browserOrFileName, setBrowserOrFileName] = useState('Planilha Atualizada');

    // useEffect(() => {
    //     const storedTaskId = localStorage.getItem('excel_task_id');
    //     if (storedTaskId) {
    //         setTaskId(storedTaskId);
    //     } else {
    //         setTaskId(null);
    //     }
    // }, [excelTaskId]);

    // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const { name, value } = e.target;

    //     if (name === 'initialDate') {
    //         setInitialDate(value);
    //     } else if (name === 'finalDate') {
    //         setFinalDate(value);
    //     }
    // };

    // const handleRowsPerPageChange = (e: SelectChangeEvent<number>) => {
    //     setRowsPerPage(Number(e.target.value)); // Atualiza o estado de rowsPerPage
    // };

    // useEffect(() => {
    //     const mockData: TableRowData[] = [
    //         {
    //             nomeArquivo: "Planilha1.xlsx",
    //             criadoPor: "João Silva",
    //             criadoEm: "2024-12-06",
    //             atualizadoEm: "2024-12-06",
    //             status: "Aprovada",
    //             totalLinhas: 500,
    //             processadasComSucesso: 480,
    //             processadasComErro: 20,
    //             exportarResultado: "Resultados1.xlsx",
    //         },
    //         {
    //             nomeArquivo: "Planilha2.xlsx",
    //             criadoPor: "Maria Souza",
    //             criadoEm: "2024-12-05",
    //             atualizadoEm: "2024-12-06",
    //             status: "Pendente",
    //             totalLinhas: 300,
    //             processadasComSucesso: 280,
    //             processadasComErro: 20,
    //             exportarResultado: "Resultados2.xlsx",
    //         },
    //         {
    //             nomeArquivo: "Planilha3.xlsx",
    //             criadoPor: "Carlos Oliveira",
    //             criadoEm: "2024-12-04",
    //             atualizadoEm: "2024-12-05",
    //             status: "Erro",
    //             totalLinhas: 100,
    //             processadasComSucesso: 70,
    //             processadasComErro: 30,
    //             exportarResultado: "Resultados3.xlsx",
    //         },
    //     ];

    //     setTableData(mockData);
    // }, []);

    // const handleExport = (row: any) => {
    //     alert(`Exportando o arquivo: ${row.exportarResultado}`);
    // };

    return (
        <Core withBlueBar withNavbar path="/pricingTable" label="Clique aqui para cadastrar uma planilha para o precificador">
            <EditionModal paramsEdit={spreadRow} setModal={setModal} state={isOpen} />
            <div
                className={styles.optionsChoiseContainer}
            >
                <button className={styles.searchButton} style={{ background: options === 'sea' ? '#00AEEF' : 'rgba(0, 0, 0, 0.20)' }} onClick={() => setOptions('sea')}>PESQUISAR</button>
                {/* <button className={styles.updateButton} style={{ background: options === 'acc' ? '#00AEEF' : 'rgba(0, 0, 0, 0.20)' }} onClick={() => setOptions('acc')}>ACOMPANHAMENTO</button> */}
                <button className={styles.updateButton} style={{ background: options === 'up' ? '#00AEEF' : 'rgba(0, 0, 0, 0.20)' }} onClick={() => setOptions('up')}>ATUALIZAR</button>
                <button className={styles.statusButton} style={{ background: options === 'sta' ? '#00AEEF' : 'rgba(0, 0, 0, 0.20)' }} onClick={() => setOptions('sta')}>STATUS</button>
            </div>
            <Grid
                container
                direction="column"
                gap={2}
                textAlign="center"
                paddingY={2}
                paddingX={2}
            >
                {
                    options === 'sea' ?
                        <Grid
                            container
                            className={styles.cardsContainer}
                        >
                            <div className={styles.cards}>
                                <TextField
                                    type="text"
                                    placeholder="Informe uma placa ou chassi para pesquisar"
                                    inputRef={textFieldRef}
                                    value={(chassi_or_plate)}
                                    className={styles.input}
                                    sx={{ height: '3rem' }}
                                    onChange={(e) => {
                                        setChassi_or_plate(e.target.value)
                                        if (textFieldRef.current?.value === '') fetchData();
                                    }}
                                />
                                <FormControl sx={{ minWidth: 250, height: '3rem' }}>
                                    <Select
                                        value={somosCode}
                                        onChange={(event) => { setSomosCode(event.target.value) }}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ color: 'gray', fontSize: '.85rem', borderRadius: '2rem', height: '3rem' }}
                                    >
                                        <MenuItem value='all'>
                                            TODOS
                                        </MenuItem>
                                        <MenuItem value='false'>SEM CÓDIGO</MenuItem>
                                        <MenuItem value='true'>COM CÓDIGO</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div
                                className={styles.optionsPricingContainer}
                            >
                                <button className={styles.buttonPricingCheck} onClick={() => { setCurrentPage(1); handleSearch(chassi_or_plate) }}><FaSearch />&nbsp;&nbsp;PESQUISAR</button>
                                {/* <button className={styles.buttonPricingCheck} style={taskId ? { background: 'rgba(0, 0, 0, 0.2)' } : {}} disabled={taskId ? true : false} onClick={() => handleSearch(chassi_or_plate, true)}><FaFileDownload />&nbsp;&nbsp;EXTRAIR XLSX</button> */}
                            </div>
                            <div
                                className={styles.optionsPricingContainer}
                            >
                                <div className={styles.totalFound}><ContentPasteSearchIcon />{totalFounded} de {totalInDataBase} resultados encontrados</div>
                            </div>
                        </Grid>
                        // : options === 'acc' ?
                        //     <Grid
                        //         container
                        //         className={styles.cardsContainer}
                        //         justifyContent="center"
                        //         textAlign="start"
                        //         alignItems="end"

                        //     >
                        //         <Grid item xs={12} sm={4} md={3}>
                        //             <Typography variant="body1">Data inicial</Typography>
                        //             <TextField
                        //                 type="date"
                        //                 id="initialDate"
                        //                 name="initialDate"
                        //                 value={initialDate}
                        //                 onChange={handleChange}
                        //                 fullWidth
                        //             />
                        //         </Grid>

                        //         <Grid item xs={12} sm={4} md={3}>
                        //             <Typography variant="body1">Data final</Typography>
                        //             <TextField
                        //                 type="date"
                        //                 id="finalDate"
                        //                 name="finalDate"
                        //                 value={finalDate}
                        //                 onChange={handleChange}
                        //                 fullWidth
                        //             />
                        //         </Grid>

                        //         {/* <Grid item xs={12} sm={4} md={3}>
                        //             <Typography variant="body1">Quantidade de linhas por página</Typography>
                        //             <FormControl fullWidth>
                        //                 <InputLabel id="rows-per-page-label">Linhas por página</InputLabel>
                        //                 <Select
                        //                     labelId="rows-per-page-label"
                        //                     id="rowsPerPage"
                        //                     value={rowsPerPage}
                        //                     onChange={handleRowsPerPageChange}
                        //                     label="Linhas por página"
                        //                 >
                        //                     <MenuItem value={10}>10</MenuItem>
                        //                     <MenuItem value={20}>20</MenuItem>
                        //                     <MenuItem value={30}>30</MenuItem>
                        //                     <MenuItem value={50}>50</MenuItem>
                        //                     <MenuItem value={100}>100</MenuItem>
                        //                 </Select>
                        //             </FormControl>
                        //         </Grid> */}

                        //         <Grid item xs={12} sm={4} md={2}>
                        //             <Button
                        //                 className="btn-inverse btn-inverse-2"
                        //                 variant="contained"
                        //                 size="large"
                        //                 color="secondary"
                        //                 type="submit"
                        //                 fullWidth
                        //                 style={{
                        //                     borderRadius: '50rem',
                        //                     height: '3rem',
                        //                     color: 'white',
                        //                     background: '#00B9F2'
                        //                 }}
                        //             >
                        //                 Filtrar
                        //             </Button>
                        //         </Grid>

                        //         <TableContainer component={Paper}>
                        //             <Table>
                        //                 <TableHead
                        //                     sx={{
                        //                         backgroundColor: "#00AEEF",
                        //                         "& th": {
                        //                             color: "white",
                        //                             fontWeight: "bold",
                        //                             textAlign: "center",
                        //                         },
                        //                     }}
                        //                 >
                        //                     <TableRow>
                        //                         <TableCell>Nome do arquivo incluído</TableCell>
                        //                         <TableCell>Criado por</TableCell>
                        //                         <TableCell>Criado em</TableCell>
                        //                         <TableCell>Atualizado em</TableCell>
                        //                         <TableCell>Status (Da planilha)</TableCell>
                        //                         <TableCell>Total de Linhas</TableCell>
                        //                         <TableCell>Processadas com sucesso</TableCell>
                        //                         <TableCell>Processadas com erro</TableCell>
                        //                         <TableCell>Exportar planilha de resultado</TableCell>
                        //                     </TableRow>
                        //                 </TableHead>
                        //                 <TableBody>
                        //                     {tableData.map((row, index) => (
                        //                         <TableRow key={index}>
                        //                             <TableCell
                        //                                 sx={{
                        //                                     textAlign: 'center'
                        //                                 }}
                        //                             >{row.nomeArquivo}
                        //                             </TableCell>
                        //                             <TableCell
                        //                                 sx={{
                        //                                     textAlign: 'center'
                        //                                 }}
                        //                             >
                        //                                 {row.criadoPor}
                        //                             </TableCell>
                        //                             <TableCell
                        //                                 sx={{
                        //                                     textAlign: 'center'
                        //                                 }}
                        //                             >
                        //                                 {row.criadoEm}</TableCell>
                        //                             <TableCell
                        //                                 sx={{
                        //                                     textAlign: 'center'
                        //                                 }}
                        //                             >
                        //                                 {row.atualizadoEm}</TableCell>
                        //                             <TableCell
                        //                                 sx={{
                        //                                     textAlign: 'center'
                        //                                 }}
                        //                             >
                        //                                 {row.status}</TableCell>
                        //                             <TableCell
                        //                                 sx={{
                        //                                     textAlign: 'center'
                        //                                 }}
                        //                             >
                        //                                 {row.totalLinhas}
                        //                             </TableCell>
                        //                             <TableCell
                        //                                 sx={{
                        //                                     textAlign: 'center'
                        //                                 }}
                        //                             >
                        //                                 {row.processadasComSucesso}
                        //                             </TableCell>
                        //                             <TableCell
                        //                                 sx={{
                        //                                     textAlign: 'center'
                        //                                 }}
                        //                             >
                        //                                 {row.processadasComErro}
                        //                             </TableCell>
                        //                             <TableCell
                        //                                 sx={{
                        //                                     textAlign: 'center'
                        //                                 }}
                        //                             >

                        //                                 <IconButton onClick={() => handleExport(row)} color="primary">
                        //                                     <GetAppIcon />
                        //                                 </IconButton>
                        //                             </TableCell>
                        //                         </TableRow>
                        //                     ))}
                        //                 </TableBody>
                        //             </Table>
                        //         </TableContainer>

                        //     </Grid>
                            : options === 'up' ?
                                <Grid
                                    container
                                    direction="row"
                                    gap={2}
                                    textAlign="center"
                                    paddingY={2}
                                    paddingX={2}
                                    className={styles.cardsContainer}
                                >
                                    <div className={styles.updatedSpreadsheetButton}>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                        <button
                                            type="button"
                                            className={styles.uploaderButton}
                                            onClick={handleButtonClick}
                                        >
                                            {browserOrFileName}
                                        </button>
                                    </div>

                                    <button className={styles.buttonPricingCheck} onClick={uploadUpdatedTable}>ATUALIZAR</button>
                                </Grid>
                                :
                                <Grid
                                    container
                                    direction="row"
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    className={styles.cardsStatusContainer}
                                >
                                    <div className={styles.spreadsheetUpdate}>
                                        <h5>Planilha: {spreadName ? spreadName : 'Nenhuma em análise'}</h5>
                                        <p className={styles.spreadsheetStatus} >Status: {spreadStatus ? spreadStatus : 'Nenhum'}</p>
                                    </div>
                                </Grid>
                }
                {
                    loading
                        ?
                        <Grid
                            container
                            direction="row"
                            alignItems={'center'}
                            justifyContent={'center'}
                            height={window.innerWidth < 900 ? "40vh" : '60vh'}
                        >
                            <CircularProgress size={60} />
                        </Grid>
                        :
                        dataSearch?.map(

                            (item: any, index: number) =>

                                <div key={`fiv-list-item-${index}`} style={{ marginTop: 20 }}>
                                    <ListItemButton onClick={() => { setIndexSelected(index) }}>
                                        <ListItemIcon>
                                            <Avatar>
                                                {
                                                    <FaTrailer />
                                                }
                                            </Avatar>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Fragment>
                                                    <Typography
                                                        variant="body1"
                                                        color="text.primary"
                                                    >
                                                        <strong>CHASSI: </strong>{item.chassi}
                                                    </Typography>
                                                </Fragment>
                                            }
                                            secondary={
                                                <Fragment>
                                                    <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body1"
                                                        color="text.primary"
                                                    >
                                                        <strong>PLACA: </strong>{item.plate}
                                                    </Typography>
                                                </Fragment>
                                            }
                                        />
                                        {index === indexSelected ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Collapse in={index === indexSelected} timeout="auto" unmountOnExit sx={{ alignItems: 'end' }}>
                                        <List component="div" disablePadding>
                                            <ListItem sx={{ pl: 4, alignItems: 'end' }}>
                                                <ListItemText
                                                    primary={
                                                        <Fragment>
                                                            <Typography
                                                                variant="body1"
                                                                color="text.primary"
                                                            >
                                                                <strong>PRODUTO: </strong>{item.product}
                                                            </Typography>
                                                            <Typography
                                                                variant="body1"
                                                                color="text.primary"
                                                            >
                                                                <strong>MARCA: </strong>{item.fabricator}
                                                            </Typography>
                                                            <Typography
                                                                variant="body1"
                                                                color="text.primary"
                                                            >
                                                                <strong>MODELO: </strong>{item.brand_model}
                                                            </Typography>
                                                            <Typography
                                                                variant="body1"
                                                                color="text.primary"
                                                            >
                                                                <strong>ANO DE FABRICAÇÂO: </strong>{item.year_fabrication}
                                                            </Typography>
                                                        </Fragment>
                                                    }
                                                />
                                                <Button variant="contained" sx={{ borderRadius: '30px', color: 'white', marginBottom: '.5rem' }} onClick={() => { setSpreadRow([item]); setModal(true) }}>VISUALIZAR</Button>
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                    <Divider />
                                </div>
                        )
                }
                {
                    !loading ?
                        Array.isArray(dataSearch) && dataSearch.length >= 1 && (
                            <div style={{ marginTop: '5rem' }}>
                                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                            </div>
                        ) : <></>
                }
            </Grid>
        </Core>
    )
}