import Core from "../../Core"
import { useFormik } from 'formik'
import { toast } from "react-toastify"
import { UserRetrieveProps } from '../types'
import * as api from "../../../services/api"
import { useAuth } from '../../../hooks/useAuth'
import { useCallback, useEffect, useState } from "react"
import useWindowSize from "../../../hooks/useWindowSize"
import { useNavigate, useParams } from "react-router-dom"
import { DefaultMessage } from "../../../utils/defaultMessages"
import { Button, Chip, Grid, TextField, Typography } from "@mui/material"

type FormPasswordProps = {
  new_password: string
  new_password_again: string
}

export default function MyAccount(): JSX.Element {
  const { id } = useParams()
  const { user } = useAuth()
  const navigate = useNavigate()
  const { width } = useWindowSize()

  // const [data, setData] = useState<UserRetrieveProps>()
  const [formPassword, setFormPassword] = useState<FormPasswordProps>({
    new_password: "",
    new_password_again: ""
  })

  const { handleChange, handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
    },
    onSubmit: updateMyAccount
  })

  const getData = useCallback(async () => {
    await api.get(`user/retrieve/${id}/`).then((response: any) => {
      setFieldValue('first_name', response.data.user.first_name)
      setFieldValue('last_name', response.data.user.last_name)
      setFieldValue('email', response.data.user.email)
      // setData(response.data)
    }).catch((error) => {
      toast.error(DefaultMessage.ERROR, {
			  position: toast.POSITION.TOP_RIGHT
		  })
    })
  }, [id, setFieldValue])

  async function updateMyAccount() {
    toast.loading("Aguarde...", {
      position: toast.POSITION.TOP_RIGHT,
    })

    const updateUrl = `user/update/my/account/`

    await api.put(updateUrl, values).then((response: any) => {
      toast.dismiss()
      if (response.status === 200) {
        toast.success("Cadastro atualizado com sucesso! Faça logout e login novamente para ver suas informações atualizadas.", {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        toast.error(response.data.content, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }).catch((error) => {
      toast.dismiss()
      toast.error(DefaultMessage.ERROR, {
			  position: toast.POSITION.TOP_RIGHT
		  })
    })
  }

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormPassword({...formPassword, [e.target.name]: e.target.value})
  }

  async function updatePassword() {
    if (formPassword.new_password === formPassword.new_password_again) {
      await api.put(`user/update/password/${id}/`, formPassword).then((response: any) => {
        if (response.status === 200) {
          toast.success("Senha atualizada com sucesso!", {
            position: toast.POSITION.TOP_RIGHT
          })
          setFormPassword({
            new_password: "",
            new_password_again: ""
          })
        } else {
          toast.error("Verifique se sua senha respeita a regra informada!", {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      }).catch((error) => {
        toast.dismiss()
        toast.error("Verifique se sua senha respeita a regra informada!", {
          position: toast.POSITION.TOP_RIGHT
        })
      })
    } else {
      toast.error("As senhas não são iguais", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }

  useEffect(() => {
    if (id !== user?.id) navigate('/')
    if (id) getData()
  }, [getData, id, navigate, user?.id])

  return (
    <Core 
      withBlueBar 
      withNavbar 
    >
      <Grid
        container
        direction={width > 600 ? "row" : "column"}
        alignContent="center"
        alignItems="center"
        textAlign="center"
        paddingBottom={10}
        // gap={width > 600 ? 0 : 3}
      >
        <Grid
          item
          md={6} sm={12}
        >
          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction="column"
              spacing={2}
              textAlign="start"
              justifyContent="center"
              paddingX={width > 600 ? 20 : 2}
            >
              <Grid
                item
                textAlign="center"
                md={12} sm={12}
              >
                <Chip label="MINHA CONTA" color="primary" />
              </Grid>
              <Grid
                item
                md={4} sm={12}
              >
                <Typography variant="body1">
                  Nome
                </Typography>
                <TextField
                  fullWidth
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="Nome"
                  value={values.first_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                md={4} sm={12}
              >
                <Typography variant="body1">
                  Sobrenome
                </Typography>
                <TextField
                fullWidth
                  type="text"
                  id="last_name"
                  name="last_name"
                  placeholder="Sobrenome"
                  value={values.last_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                md={4} sm={12}
              >
                <Typography variant="body1">
                  E-mail
                </Typography>
                <TextField
                  fullWidth
                  type="text"
                  id="email"
                  name="email"
                  placeholder="E-mail"
                  value={values.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                textAlign="center"
              >
                <Button
                  className="btn"
                  variant="contained"
                  size="large"
                  color="secondary"
                  type="submit"
                >
                  Atualizar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid
          item
          md={6} sm={12}
          style={{ marginTop: width <= 899 ? '2rem' : ''}}
        >
          <Grid
            container
            direction="column"
            spacing={2}
            textAlign="start"
            justifyContent="center"
            paddingX={width > 600 ? 20 : 2}
          >
            <Grid
              item
              md={12} sm={12}
              textAlign="center"
            >
              <Chip label="ALTERAR SENHA" color="success" />
            </Grid>
            <Grid
              item
              textAlign="center"
              md={12} sm={12}
            >
              <Typography variant="body1" color="inherit">
                Sua senha deve conter no mínimo 8 caracteres, sendo:
              </Typography>
              <Grid style={{ display: 'flex', justifyContent: "center", gap: "1rem", flexWrap: "wrap", padding: '16px'}}>
                <Chip label="1 Caractére Especial" />
                <Chip label="1 Caractére Numérico" />
                <Chip label="1 Caractére Maiúsculo" />
              </Grid>
            </Grid>
            <Grid
              item
              md={6} sm={12}
            >
              <Typography variant="body1">
                Nova senha
              </Typography>
              <TextField
                fullWidth
                type="password"
                id="new_password"
                name="new_password"
                placeholder="Nova senha"
                value={formPassword.new_password}
                onChange={handleChangePassword}
              />
            </Grid>
            <Grid
              item
              md={6} sm={12}
            >
              <Typography variant="body1">
                Repita a nova senha
              </Typography>
              <TextField
                fullWidth
                type="password"
                id="new_password_agian"
                name="new_password_again"
                placeholder="Repita a nova senha"
                value={formPassword.new_password_again}
                onChange={handleChangePassword}
              />
            </Grid>
            <Grid
              item
              textAlign="center"
            >
              <Button
                className="btn-inverse"
                variant="contained"
                size="large"
                color="secondary"
                onClick={updatePassword}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Core>
  )
}