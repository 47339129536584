import styled from "styled-components";
import CodeEditor from "@uiw/react-textarea-code-editor";

export const Container = styled.div`
    display: flex;

    @media (max-width: 600px) {
      Container {
        display: flex;
        flex-direction: column;
      }
    }
`;

export const Sidebar = styled.nav`
  width: 320px;
  background-color: #F1F1F1;
  border-right: 3px solid #00B9F2;
  color: black;
  padding: 20px;

  ul {
    list-style-type: none;
    padding: 0;
  }

  ul li {
    margin: 15px 0;
    color: black;
    text-decoration: none;
    font-size: 16px;
    position: relative; 
    cursor: pointer;
    transition: color 0.3s ease;

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      background-color: #00B9F2;
      bottom: 0;
      left: 0;
      transition: width 0.3s ease; 
    }

    &:hover {
      color: black;

      &::after {
        width: 100%; 
      }
    }
  }

  ul li.active {
      color: #475d9b; 
      &::after {
        height: 2px;
      }
  }

  ul li.active::after {
      width: 100%;
  }

  @media (max-width: 786px) {
      ul li {
          transition: none;
        
          &::after {
              height: 0px;
              transition: none; 
          }
      }
      Sidebar {
        width: 100%;
      }
    }
`;

export const MainContent = styled.div`
    flex: 1;
    padding: 20px;
    background-color: #F1F1F1;
    display: flex;
    justify-content: center;

    h1 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    @media (max-width: 600px) {
        MainContent {
          width: 100%;
          margin-top: 20px;
        }
      }
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  cursor: pointer;
  font-size: 30px;
  width: 3rem;
  height: 3rem;
  border-radius: 8px;
  border: 2px solid #00B9F2;
  color: #00B9F2;

`

export const SidebarTitulo = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: 18px;

`

export const Titulo = styled.h4`
  font-weight: 600;
  font-size: 16px;

`
export const StyledCodeEditor = styled(CodeEditor)`
  font-family: ui-monospace, SFMono-Regular, SF Mono, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 14px;
  width: 100%;
  background-color: #ffffff; 
  border-radius: 10px;
  overflow: inherit;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  color: black;

    &,
    &:focus,
    &:active,
    &:focus-visible {
      border: none !important;
      border-radius: none !important;
      box-shadow: none !important;
      outline: 0 !important;
    }

    &:focus-visible {
      outline: none;
    }
`;

export const Paragrafo = styled.p`
  margin: 1rem 0;
  font-size: 15px;
`

export const SidebarContainer = styled.div`
  // border-left: 2px solid #00B9F2;
  height: 4rem;
  padding: 1rem;
`