import colors from "./colors";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle<{ backcolor: string, overflowsigned: boolean }>`

	body {
		background-color: ${({ backcolor }) => backcolor};
		margin: 0;
		padding: 0;
	}

	::placeholder {
		color: ${colors.IceWhite};
	}

	@page {
		size: A4;
		margin: .2cm;
		-webkit-print-color-adjust: exact;
	}
	@media print {
		html, body {
			font-size: .8rem;
			overflow: ${({ overflowsigned }) => overflowsigned ? 'hidden' : 'inherit'};
			margin: 0;
			padding: 0;
			display: flex;
			align-itens: center;
			justify-content: center;
			height: 100%;
  			width: 100%;
		}
		#report {
			max-width: 100%!important;
			width: 100%;
			position: absolute;
			display: flex;
			flex-direction: column;
			overflow-y: visible;
			box-shadow: none;
			margin: 0;
		}
		#report-logo {
			display: flex!important;
			justify-content: center;
			width: 100%;
			padding: 0;
			margin-top: 30px;
			margin-bottom: 60px;
			background-color: ${colors.DarkerGray};
			border-radius: 20px;
		}
		#report-link-site {
			display: flex!important;
			justify-content: center;
			margin-top: 150px;
			font-size: 16pt;
		}
		#report-body-card {
			width: 100%;
			padding: 0;
		}
		#info-message-and-hash {
			font-size: 1rem;
		}
	}

	html, body {
		margin: 0;
		padding-bottom: 0;
		height: 100%;
		overflow-x: ${({ overflowsigned }) => overflowsigned ? 'hidden' : 'inherit'};
		color: ${colors.PenguinBlack};
		font-family: 'NerisLight', sans-serif;
		font-weight: 400;
	}

	body {
		background-color: ${({ backcolor }) => backcolor};
	}

	#report-logo, #report-link-site {
		display: none;
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		text-indent: 1px;
		text-overflow: '';
	}

	select::-ms-expand {
    display: none!important;
	}

	// MUI Styles
	.css-1k3bzjq-MuiInputBase-input-MuiOutlinedInput-input, .css-ab0xnz, textarea {
		height: 1rem;
		// border-radius: 50px!important;
		border: ${colors.DarkerGray} solid 1px!important;
		padding: 15px!important;
		background-color: ${colors.IceWhite};
		overflow: hidden;
	}
	textarea {
		border: ${colors.LoginBlue} solid 2px;
	}
	fieldset {
		border: none!important;
	}
	.css-1k3bzjq-MuiInputBase-input-MuiOutlinedInput-input, .css-ab0xnz{
		height: 1rem;
	}
	.css-rsqrfm-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
		height: 1.625rem;
	}
	.btn {
		border-radius: 30px!important;
		padding: 8px 70px!important;
		width: auto!important;
		background-color: ${colors.WaterBlue};
		color: ${colors.IceWhite}!important;
		cursor: pointer!important;
		transition: all .5s;

		:hover {
			background-color: ${colors.DarkerGray}!important;
		}

		:disabled {
			background-color: ${colors.FogGray}!important;
			color: ${colors.IceWhite}!important;
			cursor: not-allowed!important;
		}

		:focus {
			outline: none;
			background-color: ${colors.WaterBlue};
			color: ${colors.IceWhite}!important;
		}

	}
	.css-qiwgdb {
		padding: 15px 0px
	}
	.css-h4e3g4-MuiInputBase-input-MuiOutlinedInput-input {
		background: #f1f1f1;
	}
	.css-1k3bzjq-MuiInputBase-input-MuiOutlinedInput-input, .css-ab0xnz {
		background: #f1f1f1;
	}
	.css-11k11ny-MuiPaper-root-MuiDrawer-paper {
		align-items: center;
		padding-bottom: 15px;
	}
	.w-tc-editor-text {
		border: none !important;
		height: 100% !important;
		overflow: inherit !important
	}
	
	.usernameLogin {
		div > input {
			background: #f1f1f1;
		}
	}

	.css-ab0xnz {
		background: #f1f1f1;
	}

	.clientInput {
		div > input {
			background: #f1f1f1;
		}
	}

	.passwordLogin {
		div > input {
			background: #f1f1f1;
		}
	}

	.btn-inverse {
		border-radius: 30px!important;
		padding: 8px 70px!important;
		width: auto;
		background-color: ${colors.IceBlue}!important;
		color: ${colors.IceWhite}!important;
		cursor: pointer!important;
		transition: all .5s;

		:hover {
			background-color: ${colors.WaterBlue}!important;
		}

		:disabled {
			background-color: ${colors.LowBlue}!important;
			color: ${colors.FogGray}!important;
			border: ${colors.LowBlue} solid 2px!important;
			cursor: not-allowed!important;
		}
	}
	
	.btn-inverse-2 {
		width: 100%;
	}
	
	a {
		text-decoration: none;
		color: ${colors.LoginBlue};
	}
	a:hover {
		color: ${colors.LoginBlue};
	}
	ul {
		list-style: none;
	}
	.MuiSelect-outlined {
		border-radius: 30px!important;
		border: ${colors.DarkerGray} solid 1px!important;
		overflow: hidden;
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
	}
	
	.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
		padding: 16px 0px;
	}

	.css-t61uqx-MuiSvgIcon-root-MuiSelect-icon, .css-aqy6p5-MuiSvgIcon-root-MuiSelect-icon {
		color: white;
	}

	.btn-link {
		color: ${colors.DarkerGray}!important;
		text-transform: capitalize!important;
		border: none!important;
		background-color: transparent!important;
		text-decoration: none;

		:hover {
			border: none!important;
			background-color: transparent!important;
		}
	}
	
	.MuiInputBase-input, .MuiSelect-select {
		border-radius: 50rem;
		height: 1rem;
	}
	
	.css-4klk8k.MuiSelect-select {
		height: 13px;
		min-height: 13px;
	}

	.MuiInputBase-input, .css-1f3slql {
		border-radius: 50rem !important;
	}

	.boxButton {
		font-size: .6rem;
	}

	@media (max-width: 900px) {
		html, body {
			font-size: 9pt;
		}

		.btn, .btn-inverse {
			padding: 6px 35px!important;
			font-size: 8pt;
		}

		.css-1k3bzjq-MuiInputBase-input-MuiOutlinedInput-input, .css-ab0xnz, textarea {
			height: 10px!important;
			padding: 12px!important;
		}
		.css-rsqrfm-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
			min-height: 1rem;
			max-height: 1.334rem;
		}

		.MuiSelect-select {
			min-height: 1rem;
			max-height: 1.334rem;
		}
		
		.css-4klk8k.MuiSelect-select {
			height: 10px;
			min-height: 10px;
			padding: 12px;
		}
	}

	@media (min-width: 1210px) {
		.boxButton {
			font-size: 1rem;
		}
	}

	.btnSignUp {
		transition: all .5s;

		:hover {
			background-color: ${colors.WaterBlue}!important;
		}

		:disabled {
			background-color: ${colors.LowBlue}!important;
			color: ${colors.FogGray}!important;
			border: ${colors.LowBlue} solid 2px!important;
			cursor: not-allowed!important;
		}
	}

	.css-i6cut0-MuiGrid-root {
		margin-top: 50px;
	}

	.tailwindField {
		height: 20px;
	}

`;

export default GlobalStyles;
