import * as api from '../services/api'
import { toast } from 'react-toastify'
import { cookies } from '../hooks/useCookies'
import { createContext, useEffect, useState, useCallback } from 'react'

type LoginResponse = {
  id: string
  first_name: string
  last_name: string
  api_user: boolean
  email: string
  company_id: string
  products: string[]
  first_login: boolean
  can_upload_table: boolean
  is_representative: boolean
  is_admin: boolean
  is_staff: boolean
  access: string
  refresh: string
}

type ContentResponse = {
  id: string,
  email: string,
  first_login: boolean,
  blocked: boolean,
  can_upload_table: boolean,
  can_query_chassi_or_plate: boolean,
  can_manage_query_chassi_or_plate: boolean,
  is_representative: boolean,
  is_admin: boolean,
  is_staff: boolean,
  is_active: boolean,
  is_superuser: boolean,
  first_name: string,
  last_name: string,
  products: Array<string>,
  api_user: boolean,
  plan_balance: {
    plan: number | null,
    tr_balance: number | null,
    hp_balance: number | null,
    sr_balance: number | null,
    cr_balance: number | null,
    cm_balance: number | null,
    hv_balance: number | null,
    tr_count_company: number | null,
    hp_count_company: number | null,
    sr_count_company: number | null,
    cr_count_company: number | null,
    cm_count_company: number | null,
    hv_count_company: number | null,
    tr_count_user: number,
    hp_count_user: number,
    sr_count_user: number,
    cr_count_user: number,
    cm_count_user: number,
    hv_count_user: number
  }
}

interface AuthContextData {
  signed: boolean
  toastLogin: boolean
  user: LoginResponse | null
  content: ContentResponse | null
  Logout(mode?: boolean): void
  Login(user: object): Promise<void>
  getUserData(userId: string | undefined): Promise<void> 
  setToastLogin: React.Dispatch<React.SetStateAction<boolean>>
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export function AuthProvider({ children }: any) {
  const [user, setUser] = useState<LoginResponse | null>(null)
  const [content, setContent] = useState<ContentResponse | null>(null)
  const [toastLogin, setToastLogin] = useState<boolean>(false)

  function defaultSession(mode = false) {
    setUser(null)
    cookies.remove('@Somos:user')
    cookies.remove('@Somos:access')
    cookies.remove('@Somos:refresh')
    cookies.remove('@Somos:content')
    if (mode) {
      return
    } else {
      window.location.pathname = '/'
    }
  }

  async function Login(userData: object) {
    toast.loading("Aguarde...", {
      position: toast.POSITION.TOP_RIGHT,
    })

    await api.post('user/token/', userData).then((response: any) => {
      toast.dismiss()
      if (response.status === 200) {
        const data = response.data as LoginResponse
        if (data?.api_user === true) {
          toast.error('Você é um usuário somente para API, entre em contato com o suporte para mais informações')
        } else {
          setUser(data)
          api.defaults.headers.Authorization = `Bearer ${data.access}`
          cookies.set('@Somos:user', JSON.stringify(data), { path: '/', sameSite: 'lax' })
          cookies.set('@Somos:access', data.access, { path: '/', sameSite: 'lax' })
          cookies.set('@Somos:refresh', data.refresh, { path: '/', sameSite: 'lax' })
        }
      } else {
        toast.error(response.data.detail, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }).catch((error) => {
      toast.error(error.response.data.detail, {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }

  const getUserData = useCallback (async (userId: string) => {
    await api.get(`user/retrieve/${userId}/`).then((response: any) => {
      setContent(response.data.content);
      cookies.set('@Somos:content', JSON.stringify(content), { path: '/', sameSite: 'lax' })
    }).catch((error) => {
      toast.error('Ops, algo deu errado.', {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }, [])

  function Logout(mode = false) {
    defaultSession(mode);
    localStorage.removeItem('navbarState');
    localStorage.removeItem('excel_task_id');
  }

  useEffect(() => {
    const cookieUser = cookies.get('@Somos:user')
    const cookieAccess = cookies.get('@Somos:access')
    const cookieRefresh = cookies.get('@Somos:refresh')
    const CookieContent = cookies.get('@Somos:content')

    if (cookieUser && cookieAccess && cookieRefresh && CookieContent) {
      setUser(cookieUser)
      setContent(CookieContent)
      api.defaults.headers.Authorization = `Bearer ${cookieAccess}`
    }

  }, [])

  return (
    <AuthContext.Provider value={{ signed: Boolean(user), user, content, Login, getUserData, Logout, setToastLogin, toastLogin }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;